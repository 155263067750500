import React, { useRef, useState, useEffect } from "react"
import { Link } from "gatsby"

export default function TemporaryHome() {
  const textarea = useRef(null);
  const firstname = useRef(null);
  const [mode, setMode] = useState(null);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (mode === "try") {
      textarea.current.focus();                    
    } else if(mode === "api"){
      firstname.current.focus();     
    }
  }, [mode]);

  return (
    <div className="antialiased text-gray-900 px-0">
      <div className="bg-amber-400">
          <header className="relative z-50 w-full flex-none text-sm font-semibold leading-6">
              <nav aria-label="Global" className="mx-auto max-w-container px-4 sm:px-6 lg:px-8">
                  <div className="relative flex items-center py-[2.125rem]">
                      <a className="mr-auto flex-none text-slate-900" href="/">
                          <svg id="logo" data-name="logo" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 545.68 545.7">
                              <rect x="86.69" y="86.69" width="392.46" height="392.46" rx="37.82" transform="translate(272.83 -127.26) rotate(45)" fill="#ffb000" />
                              <path d="M282.92,555.77a48.5,48.5,0,0,1-34.52-14.3l-224-224a48.87,48.87,0,0,1,0-69l224-224a48.82,48.82,0,0,1,69,0l224,224a48.8,48.8,0,0,1,0,69l-224,224A48.52,48.52,0,0,1,282.92,555.77Zm0-523.7a26.63,26.63,0,0,0-19,7.85l-224,224a26.85,26.85,0,0,0,0,37.92l224,224a26.8,26.8,0,0,0,37.92,0l224-224a26.8,26.8,0,0,0,0-37.92l-224-224A26.67,26.67,0,0,0,282.92,32.07Z"
                              transform="translate(-10.09 -10.07)" />
                              <polygon points="215.39 282.61 136.27 203.48 155.78 183.97 215.39 243.58 273.31 185.66 330.79 243.14 389.87 184.06 409.39 203.57 330.79 282.17 273.31 224.69 215.39 282.61" />
                              <path d="M225.48,296.21l-82.66-82.66,23.05-23.05,59.61,59.61,57.92-57.92,57.48,57.48L400,190.59l23.05,23-82.13,82.13L283.4,238.29Zm-75.59-82.66,75.59,75.59,57.92-57.92,57.48,57.48,75.06-75.06-16-16-59.08,59.08L283.4,199.27l-57.92,57.91-59.61-59.61Z"
                              transform="translate(-10.09 -10.07)" />
                              <polygon points="215.39 394.55 136.27 315.43 155.78 295.92 215.39 355.52 273.31 297.61 330.79 355.09 389.87 296 409.39 315.51 330.79 394.11 273.31 336.64 215.39 394.55" />
                              <path d="M225.48,408.16,142.82,325.5l23.05-23.05,59.61,59.61,57.92-57.92,57.48,57.48L400,302.53,423,325.58l-82.13,82.14L283.4,350.24ZM149.89,325.5l75.59,75.58,57.92-57.91,57.48,57.48,75.06-75.07-16-16-59.08,59.09L283.4,311.21l-57.92,57.92-59.61-59.61Z"
                              transform="translate(-10.09 -10.07)" />
                          </svg>
                          <span className="sr-only">Wordwise</span>

                      </a>
                      <div className="hidden lg:flex lg:items-center">
                          <a className="ml-8" href="/">
                        {/* Lexicon */}   
                      </a>
                      </div>

                      <div className="hidden lg:ml-8 lg:flex lg:items-center lg:border-slate-900/15 lg:pl-8">
                          <div className="relative">
                              <button className="flex items-center font-semibold hover:text-gray-900" id="headlessui-menu-button-1" type="button" aria-haspopup="true" aria-expanded="false">
                                  <span className="hidden items-center sm:flex">
                              {/* Login */}   
                            </span>
                              </button>
                          </div>
                      </div>
                  </div>
              </nav>
          </header>
          <main id="hero" className={`pb-10 mx-auto max-w-7xl mt-0 px-4 ${mode ? 'sm:pt-0 ' : 'sm:pt-20 '}text-slate-900`}>
              <div className="text-center">
                  <h1 className={`text-3xl font-bold tracking-tight ${mode ? 'sm:text-5xl md:text-4xl ' : 'sm:text-5xl md:text-5xl '}text-slate-900`}>
                  <span className="block xl:inline">Stop risky words before they hurt.</span>
              </h1>
                  <p className="mt-3 px-12 sm:px-0 max-w-md mx-auto text-base text-slate-900 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl ">
                      Wordwise is more than just a <Link to="/">profanity filter</Link>. It's the <strong>safest</strong> way brands in regulated markets communicate with customers in writing.
                  </p>
                  <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                      {mode === 'try' || submit ? '' : (
                      <div className="rounded-md shadow">
                          <button onClick={()=> { setMode("try") }} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-slate-900 hover:bg-slate-800 md:py-4 md:text-lg md:px-10" > Try it out
                          </button>
                      </div>)} 
                      {mode === 'api' && !submit ? '' : (
                      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                          <button onClick={()=> { firstname.current.focus(); setMode("api") }} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-slate-900 bg-white hover:bg-gray-100 md:py-4 md:text-lg
                              md:px-10" > Request API Key
                          </button>
                      </div>)}
                  </div>
              </div>
          </main>
      </div>
      <main className="bg-slate-900 py-12 sm:py-20">
          <div className="mx-auto mx-auto max-w-container px-4 sm:px-6 lg:px-8 max-w-7xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-top">
                      <div>
                          <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-5xl">
                      See how it protects<span className="text-amber-500">{mode === 'api' ? ' your' : ''}</span>{mode === 'api' ? ' people and users' : ''}.
                    </h1>
                          <p className={`mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl ${mode==='api' ? '' : 'hidden'}`}>
                              See why platforms trust Wordwise to protect workers from sending risky messages to customers.
                          </p>
                          <p className={`mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl ${mode==='api' ? 'hidden' : ''}`}>
                              Tens of thousands of phrases <strong>can mean something else</strong>. Wordwise catches these, as they are typed.
                          </p>

                          <div className={mode==='api' ? 'hidden sm:block' : ''}>
                              <div className="mt-8 select-none">
                                  <span className="border-blue-400 text-blue-400 border-2 mr-2 inline rounded-full text-sm font-bold px-3 py-1">cultural differences</span>
                                  <span className="border-lime-500 text-lime-500 border-2 mr-2 inline rounded-full text-sm font-bold px-3 py-1">defensiveness</span>
                              </div>
                              <div className="mt-4 select-none">
                                  <span className="border-red-400 text-red-400 border-2 mr-2 inline rounded-full text-sm font-bold px-3 py-1">hot button issues</span>
                                  <span className="border-indigo-500 text-indigo-500 border-2 mr-2 rounded-full text-sm font-bold px-3 py-1">labels</span>
                                  <span className="border-red-600 text-red-600 border-2 rounded-full text-sm font-bold px-3 py-1">toxicity</span>
                              </div>
                              <div className="mt-4 select-none">
                                  <span className="border-purple-500 text-purple-500 border-2 mr-2 inline rounded-full text-sm font-bold px-3 py-1">socially risky phases</span>
                                  <span className="border-teal-500 text-teal-500 border-2 rounded-full text-sm font-bold px-3 py-1">over-promising</span>
                              </div>
                          </div>

                      </div>
                  </div>
                  <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                      <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto rounded-lg sm:overflow-hidden">
                          <div className="px-4 py-8 sm:px-10">
                              <div className={submit ? 'block' : 'hidden'}>
                                  <div className="mt-1 grid gap-3">
                                      <h2 className="mt-4 text-2xl font-bold tracking-tight ">Request received!</h2>
                                      <p>We'll be in touch to confirm your use case.</p>
                                  </div>
                              </div>

                              <div className={mode==='api' && !submit ? 'block' : 'hidden'}>
                                  <div className="mt-1 grid gap-3">
                                      <div>
                                          <label htmlFor="firstname" className="sr-only">
                                              First Name
                                          </label>
                                          <input id="firstname" ref={firstname} name="firstname" type="text" placeholder="First Name" autoComplete="firstname" required className="form-input block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                          />
                                      </div>
                                      <div>
                                          <label htmlFor="lastname" className="sr-only">
                                              Last Name
                                          </label>
                                          <input id="lastname" name="lastname" type="text" placeholder="Last Name" autoComplete="lastname" required className="form-input block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                                      </div>
                                      <div>
                                          <label htmlFor="lastname" className="sr-only">
                                              Work Email
                                          </label>
                                          <input id="email" name="email" type="email" placeholder="Your Email" autoComplete="email" required className="form-input block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md" />
                                      </div>
                                      <div>
                                          <label htmlFor="usecase" className="sr-only">
                                              Your Use Case
                                          </label>
                                          <textarea rows={2} id="usecase" name="usecase" placeholder="Describe your use case" className="form-textarea block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"></textarea>
                                      </div>
                                  </div>

                                  <div className="mt-6">
                                      <button type="submit" onClick={()=> { setSubmit(true) }} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2
                                          focus:ring-indigo-500" > Request Access
                                      </button>
                                  </div>
                              </div>

                              <div className={mode==='api' ? 'hidden' : 'block'}>
                                  <div className="mt-1 grid gap-3">
                                      <div>
                                          <label htmlFor="phrase" className="sr-only">
                                              Type some words to check for risk:
                                          </label>
                                          <textarea ref={textarea} rows={4} id="phrase" placeholder="Example: We have the best rates in the city." className="form-textarea block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"></textarea>
                                      </div>


                                      <div className="mt-6">
                                          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                              Check this
                                          </button>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="px-4 py-6 bg-gray-50 border-t-2 rounded-lg border-gray-200 sm:px-10">
                              <p className="text-xs leading-5 text-gray-500">
                                  By using this tool you agree to our{' '}
                                  <a href="#" className="font-medium text-gray-900 hover:underline">
                              Terms
                            </a>. We don't use cookies.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </main>
      <footer className="bg-slate-900">
        <div className="mx-auto max-w-container px-4 sm:px-6 lg:px-8 py-12">
            <p className="text-center text-xs leading-5 text-gray-500">
                &copy; 2024 Wordwise&trade;. All rights reserved.
            </p>
        </div>
      </footer>
                
  </div>
  )
}