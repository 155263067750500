import React from "react";
import { Link } from "gatsby";

export function LoginPage() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
       
        `,
        }}
      />
      <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <div className="flex flex-col">
              
              <div className="mt-5">
                <h2 className="text-lg font-semibold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm text-gray-700">
                  Don’t have an account?{" "}
                  <Link
                    className="font-medium text-blue-600 hover:underline"
                    to="/sandbox/register"
                  >
                    Sign up
                  </Link>{" "}
                  for a free trial.
                </p>
              </div>
            </div>
            <form action="#" className="mt-10 grid grid-cols-1 gap-y-8">
              <div className="">
                <label
                  htmlFor="email"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="">
                <label
                  htmlFor="password"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div>
                <button
                  className="group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-500 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 w-full"
                  type="submit"
                >
                  <span>
                    Sign in <span aria-hidden="true">→</span>
                  </span>
                </button>
              </div>
              <Link
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-2 px-4 bg-blue-500 text-white hover:bg-slate-500"
                to="/sandbox/"
              >
                <span>
                  Back to home
                </span>
              </Link>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
