import React from "react";
import { Link } from "gatsby"
import Layout from "../components/Layout"
import TemporaryHome from "../components/TemporaryWordwiseHome"
const HomePage = () => {
  
  return (
    <Layout>
        <TemporaryHome />
    </Layout>
  );
}

export default HomePage;