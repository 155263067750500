import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

export function FormPage() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
       
        `,
        }}
      />

      <div>
        <Header />
        <div className="relative -mt-[5.75rem] overflow-hidden pt-[5.75rem] pb-16">
          <div className="relative mx-auto mt-16 grid w-full max-w-container grid-cols-1 px-4 sm:mt-20 sm:px-6 lg:px-8 xl:mt-32">
            <div className="relative -mt-[5.75rem] -mb-32 overflow-hidden pt-[5.75rem] pb-32">
              <img
                src="/img/beams-basic.png"
                alt=""
                className="absolute top-0 left-1/2 -ml-[39rem] w-[113.125rem] max-w-none"
              />
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="relative mx-auto max-w-[37.5rem] pt-20 text-center pb-24">
                  <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 sm:text-5xl">
                    Team settings
                  </h1>
                  <p className="mt-4 text-base leading-7 text-slate-600">
                    Manage your team name, add and remove team members, and view
                    your billing history
                  </p>
                </div>
              </div>
              <div className="relative mx-auto w-full max-w-[40rem] space-y-16 divide-y divide-slate-100">
                <section className="px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center text-base font-semibold leading-7 text-slate-900">
                    
                    <h2 className="ml-4">Team name</h2>
                  </div>
                  <div className="mt-3 max-w-none prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
                    <p>
                      Update the name of your team. This is what team members
                      see when they visit your team invite link.
                    </p>
                  </div>
                  <form action="" className="mt-8">
                    <div className="">
                      <label
                        htmlFor="name"
                        className="block text-sm font-semibold leading-6 text-gray-900"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="mt-2 appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200 "
                      />
                    </div>
                    <div className="mt-4 sm:flex sm:items-center sm:space-x-4 sm:space-x-reverse">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700"
                      >
                        <span>Update name</span>
                      </button>
                    </div>
                  </form>
                </section>
                <section className="px-4 pt-16 sm:px-6 lg:px-8">
                  <div className="flex items-center text-base font-semibold leading-7 text-slate-900">
                    
                    
                    <h2 className="ml-4">Team members</h2>
                  </div>
                  <div className="mt-3 max-w-none prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
                    <p>
                      Here are the members on your team. You can change their
                      role to either "Owner", which gives them the ability to
                      manage the team settings, or "Member" to simply grant them
                      access to your Tailwind UI account.
                    </p>
                  </div>
                  <p className="mt-8 text-sm font-semibold leading-6 text-slate-900">
                    Share this link with your team to give them access to
                    Tailwind UI.
                  </p>
                  <div className="mt-2 flex">
                    <div className="flex h-10 min-w-0 flex-auto select-all items-center rounded-md px-3 text-slate-600 shadow-sm ring-1 ring-slate-200 sm:text-sm">
                      <div className="truncate">
                        https://tailwindui.com/teams/invite/5sz17rOmcgbQJMTZWONiTntTadqFadFj
                      </div>
                    </div>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700 relative ml-4 flex-none"
                    >
                      <span>
                        <span>Copy invite link</span>
                      </span>
                    </button>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-sky-500 hover:text-sky-600"
                    >
                      Reset your invite link
                    </button>
                  </div>
                  <table className="mt-8 w-full rounded-lg bg-white text-sm leading-6 shadow ring-1 ring-slate-900/5">
                    <caption className="sr-only">Team members</caption>
                    <thead className="border-b border-slate-200 text-left text-slate-900">
                      <tr>
                        <th className="py-3 pl-4 font-semibold sm:pl-6">
                          Email
                        </th>
                        <th className="hidden py-3 pl-6 font-semibold sm:table-cell">
                          Role
                        </th>
                        <th className="py-3 pl-6 pr-4">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="whitespace-nowrap">
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            kevin
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            owner
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          owner
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium"></td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            abdalla
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-20"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            david
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-21"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            ian
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-22"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            jake.barron@koons.com
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-23"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            jerome@8twelve.mortgage
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-24"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            pham
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-25"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="w-full max-w-0 py-3 pl-4 sm:w-2/3 sm:pl-6">
                          <div className="truncate text-slate-900">
                            xiao
                          </div>
                          <div className="capitalize text-slate-600 sm:hidden">
                            member
                          </div>
                        </td>
                        <td className="hidden w-1/3 py-3 pl-6 capitalize text-slate-600 sm:table-cell">
                          member
                        </td>
                        <td className="w-0 py-3 pl-6 pr-4 font-medium">
                          <div className="relative">
                            <button
                              className="flex h-6 w-6 items-center justify-center rounded-md text-slate-600 hover:bg-slate-50"
                              id="headlessui-menu-button-26"
                              type="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="sr-only">Actions</span>
                              
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
                <section className="px-4 pt-16 sm:px-6 lg:px-8">
                  <div className="flex items-center text-base font-semibold leading-7 text-slate-900">
                    
                    <h2 className="ml-4">Purchase history</h2>
                  </div>
                  <div className="mt-3 max-w-none prose-sm prose prose-slate prose-a:font-semibold prose-a:text-sky-500 hover:prose-a:text-sky-600">
                    <p>
                      To create a more detailed invoice with any extra
                      information you need to include for your own accounting
                      purposes, click the "View Receipt" link, and then select
                      the "Edit business address" option.
                    </p>
                  </div>
                  <table className="mt-8 w-full rounded-lg bg-white text-sm leading-6 shadow ring-1 ring-gray-900/5">
                    <caption className="sr-only">Purchase history</caption>
                    <thead className="border-b border-slate-200 text-left text-slate-900">
                      <tr>
                        <th className="w-0 py-3 pl-4 font-semibold sm:pl-6">
                          Date
                        </th>
                        <th className="w-full py-3 pl-6 font-semibold">
                          Package
                        </th>
                        <th className="hidden w-0 py-3 pl-6 text-right font-semibold sm:table-cell">
                          Price
                        </th>
                        <th className="w-0 py-3 pl-6 pr-4 sm:pr-6">
                          <span className="sr-only">Receipt</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="whitespace-nowrap">
                      <tr className="border-t border-slate-200 first:border-0">
                        <td className="py-3 pl-4 align-top text-slate-600 sm:pl-6">
                          2022-05-02
                        </td>
                        <td className="max-w-0 py-3 pl-6 text-slate-900">
                          <div className="truncate">
                            All-access - Team License
                          </div>
                          <div className="font-semibold sm:hidden">
                            CA$650.00
                          </div>
                        </td>
                        <td className="hidden py-3 pl-6 text-right font-semibold tabular-nums text-slate-900 sm:table-cell">
                          CA$650.00
                        </td>
                        <td className="py-3 pl-6 pr-4 align-top sm:pr-6">
                          <a
                            href="/purchases/49617/receipt"
                            className="font-semibold text-sky-500 hover:text-sky-600"
                          >
                            Receipt
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default FormPage;
