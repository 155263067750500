import React from "react";
import { Link } from "gatsby";

export function FormPage() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
       
        `,
        }}
      />

<div className="antialiased text-gray-900 px-6">
      <div className="max-w-xl mx-auto py-12 divide-y md:max-w-4xl">
        <div className="py-8">
          <h1 className="text-4xl font-bold">Form samples</h1>
          <p className="mt-2 text-lg text-gray-600">
            A subtitle here
          </p>
          <div className="mt-4 flex space-x-4">
                <Link
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-blue-500 text-white hover:bg-slate-500"
                to="/"
              >
                <span>
                  Back
                </span>
              </Link>
          </div>
        </div>
        <div className="py-12">
          <h2 className="text-2xl font-bold">Unstyled</h2>
          <p className="mt-2 text-lg text-gray-600">This is how form elements look out of the box.</p>
          <div className="mt-8 max-w-md">
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-gray-700">Full name</span>
                <input type="text" className="form-input mt-1 block w-full" placeholder="" />
              </label>
              <label className="block">
                <span className="text-gray-700">Email address</span>
                <input type="email" className="form-input mt-1 block w-full" placeholder="john@example.com" />
              </label>
              <label className="block">
                <span className="text-gray-700">When is your event?</span>
                <input type="date" className="form-input mt-1 block w-full" />
              </label>
              <label className="block">
                <span className="text-gray-700">What type of event is it?</span>
                <select className="form-select block w-full mt-1">
                  <option>Corporate event</option>
                  <option>Wedding</option>
                  <option>Birthday</option>
                  <option>Other</option>
                </select>
              </label>
              <label className="block">
                <span className="text-gray-700">Additional details</span>
                <textarea className="form-textarea mt-1 block w-full" rows={3}></textarea>
              </label>
              <div className="block">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="ml-2">Email me news and special offers</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-12">
          <h2 className="text-2xl font-bold">Simple</h2>
          <div className="mt-8 max-w-md">
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-gray-700">Full name</span>
                <input type="text" className="
                    form-input 
                    
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " placeholder="" />
              </label>
              <label className="block">
                <span className="text-gray-700">Email address</span>
                <input type="email" className="
                    form-input 
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " placeholder="john@example.com" />
              </label>
              <label className="block">
                <span className="text-gray-700">When is your event?</span>
                <input type="date" className="
                    form-input
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " />
              </label>
              <label className="block">
                <span className="text-gray-700">What type of event is it?</span>
                <select className="
                    form-select
                    
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  ">
                  <option>Corporate event</option>
                  <option>Wedding</option>
                  <option>Birthday</option>
                  <option>Other</option>
                </select>
              </label>
              <label className="block">
                <span className="text-gray-700">Additional details</span>
                <textarea className="
                    form-textarea
                    
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                  " rows={3}></textarea>
              </label>
              <div className="block">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="
                          form-checkbox
                          rounded
                          border-gray-300
                          text-indigo-600
                          shadow-sm
                          focus:border-indigo-300
                          focus:ring
                          focus:ring-offset-0
                          focus:ring-indigo-200
                          focus:ring-opacity-50
                        "  />
                      <span className="ml-2">Email me news and special offers</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-12">
          <h2 className="text-2xl font-bold">Underline</h2>
          <div className="mt-8 max-w-md">
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-gray-700">Full name</span>
                <input type="text" className="
                    form-input
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 
                    focus:ring-0 focus:border-black 
                  " placeholder="" />
              </label>
              <label className="block">
                <span className="text-gray-700">Email address</span>
                <input type="email" className="
                    form-input
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 
                    focus:ring-0 focus:border-black 
                  " placeholder="john@example.com" />
              </label>
              <label className="block">
                <span className="text-gray-700">When is your event?</span>
                <input type="date" className="
                    form-input
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 
                    focus:ring-0 focus:border-black 
                  " />
              </label>
              <label className="block">
                <span className="text-gray-700">What type of event is it?</span>
                <select className="
                    form-select
                    
                    block
                    w-full
                    mt-0
                    px-0.5
                    border-0 border-b-2 border-gray-200 
                    focus:ring-0 focus:border-black 
                  ">
                  <option>Corporate event</option>
                  <option>Wedding</option>
                  <option>Birthday</option>
                  <option>Other</option>
                </select>
              </label>
              <label className="block">
                <span className="text-gray-700">Additional details</span>
                <textarea className="
                    form-textarea
                    
                    mt-0
                    block
                    w-full
                    px-0.5
                    border-0 border-b-2 border-gray-200 
                    focus:ring-0 focus:border-black 
                  " rows={2}></textarea>
              </label>
              <div className="block">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="
                          form-checkbox 
                          border-gray-300 border-2 
                          
                          text-black
                          focus:border-gray-300 focus:ring-black
                        " />
                      <span className="ml-2">Email me news and special offers</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-12">
          <h2 className="text-2xl font-bold">Solid</h2>
          <div className="mt-8 max-w-md">
            <div className="grid grid-cols-1 gap-6">
              <label className="block">
                <span className="text-gray-700">Full name</span>
                <input type="text" className="
                    form-input 
                    
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500  focus:ring-0
                  " placeholder="" />
              </label>
              <label className="block">
                <span className="text-gray-700">Email address</span>
                <input type="email" className="
                    form-input
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500  focus:ring-0
                  " placeholder="john@example.com" />
              </label>
              <label className="block">
                <span className="text-gray-700">When is your event?</span>
                <input type="date" className="
                    form-input
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500  focus:ring-0
                  " />
              </label>
              <label className="block">
                <span className="text-gray-700">What type of event is it?</span>
                <select className="
                    form-select
                    
                    block
                    w-full
                    mt-1
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500  focus:ring-0
                  ">
                  <option>Corporate event</option>
                  <option>Wedding</option>
                  <option>Birthday</option>
                  <option>Other</option>
                </select>
              </label>
              <label className="block">
                <span className="text-gray-700">Additional details</span>
                <textarea className="
                    form-textarea
                   
                    mt-1
                    block
                    w-full
                    rounded-md
                    bg-gray-100
                    border-transparent
                    focus:border-gray-500  focus:ring-0
                  " rows={3}></textarea>
              </label>
              <div className="block">
                <div className="mt-2">
                  <div>
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="
                          form-checkbox
                          rounded
                          bg-gray-200
                          border-transparent
                          focus:border-transparent
                          text-gray-700 
                          focus:ring-1 focus:ring-offset-2 focus:ring-gray-500
                        " />
                      <span className="ml-2">Email me news and special offers</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default FormPage;
