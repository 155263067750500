import React from "react";
import {Link} from "gatsby";

export function RegisterPage() {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
       
        `,
        }}
      />

      <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <div className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <div className="flex flex-col">
              <div className="mt-5">
                <h2 className="text-lg font-semibold text-gray-900">
                  Start for free
                </h2>
                <p className="mt-2 text-sm text-gray-700">
                  Already registered?{" "}
                  <Link
                    className="font-medium text-blue-600 hover:underline"
                    to="/sandbox/login"
                  >
                    Sign in
                  </Link>{" "}
                  to your account.
                </p>
              </div>
            </div>
            <form
              action="#"
              className="mt-10 grid grid-cols-1 gap-y-8 gap-x-6 sm:grid-cols-2"
            >
              <div className="">
                <label
                  htmlFor="first_name"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <input
                  id="first_name"
                  type="text"
                  name="first_name"
                  autoComplete="given-name"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="">
                <label
                  htmlFor="last_name"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <input
                  id="last_name"
                  type="text"
                  name="last_name"
                  autoComplete="family-name"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="password"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="referral_source"
                  className="mb-3 block text-sm font-medium text-gray-700"
                >
                  How did you hear about us?
                </label>
                <select
                  id="referral_source"
                  name="referral_source"
                  className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm pr-8"
                >
                  <option>AltaVista search</option>
                  <option>Super Bowl commercial</option>
                  <option>Our route 34 city bus ad</option>
                  <option>The “Never Use This” podcast</option>
                </select>
              </div>
              <div className="col-span-full">
                <button
                  className="group inline-flex items-center justify-center rounded-lg py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-500 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 w-full"
                  type="submit"
                >
                  <span>
                    Sign up <span aria-hidden="true">→</span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
