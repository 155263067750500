import React from "react"

const ColorsPage = () => {
  const renderColor = colorClass => {
    return (
      <>
        <div className={`h-16 inline-block ${colorClass}`}></div>
        <div>{colorClass}</div>
      </>
    )
  }
  
  return (
    <div className="relative flex min-h-full justify-center md:px-12 lg:px-0">
      <div className="relative z-10 flex flex-1 flex-col bg-white py-10 px-4 shadow-2xl sm:justify-center md:flex-none md:px-28">
        <div className="mx-auto w-full md:w-[50vw]">
          <div>
            <h1 className="text-center text-3xl mb-8">Color Panel</h1>
          </div>
          <h2 className="text-xl">Primary Colors</h2>
          <div className="grid grid-rows-2 grid-flow-col gap-4">
            {renderColor("bg-primary-1")}
            {renderColor("bg-primary-2")}
          </div>

          <div className="grid grid-rows-2 grid-flow-col gap-4 mb-4">
            {renderColor("bg-primary-3")}
            {renderColor("bg-primary-4")}
            {renderColor("bg-primary-5")}
          </div>

          <h2 className="text-xl mb-2">Secondary Colors</h2>

          <div className="grid grid-rows-2 grid-flow-col gap-4">
            {renderColor("bg-secondary-1")}
            {renderColor("bg-secondary-2")}
            {renderColor("bg-secondary-3")}
          </div>
          <div className="grid grid-rows-2 grid-flow-col gap-4 mb-4">
            {renderColor("bg-secondary-4")}
            {renderColor("bg-secondary-5")}
            {renderColor("bg-secondary-6")}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ColorsPage
