exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sandbox-account-tsx": () => import("./../../../src/pages/sandbox/account.tsx" /* webpackChunkName: "component---src-pages-sandbox-account-tsx" */),
  "component---src-pages-sandbox-colors-tsx": () => import("./../../../src/pages/sandbox/colors.tsx" /* webpackChunkName: "component---src-pages-sandbox-colors-tsx" */),
  "component---src-pages-sandbox-forms-tsx": () => import("./../../../src/pages/sandbox/forms.tsx" /* webpackChunkName: "component---src-pages-sandbox-forms-tsx" */),
  "component---src-pages-sandbox-index-tsx": () => import("./../../../src/pages/sandbox/index.tsx" /* webpackChunkName: "component---src-pages-sandbox-index-tsx" */),
  "component---src-pages-sandbox-login-tsx": () => import("./../../../src/pages/sandbox/login.tsx" /* webpackChunkName: "component---src-pages-sandbox-login-tsx" */),
  "component---src-pages-sandbox-register-tsx": () => import("./../../../src/pages/sandbox/register.tsx" /* webpackChunkName: "component---src-pages-sandbox-register-tsx" */)
}

