import React from "react";
import { Link } from "gatsby"

const IndexPage = () => {
  
  return (
    <>
        <div className="relative overflow-hidden pb-16">
          <div className="relative mx-auto mt-16 grid w-full max-w-container grid-cols-1 px-4 sm:mt-20 sm:px-6 lg:px-8 xl:mt-32">
              Exo Sample
            
            <h1 className="col-start-1 row-start-2 mt-4 max-w-[36rem] text-4xl font-extrabold tracking-tight text-blue-500 sm:text-7xl"></h1>
            <p className="col-start-1 row-start-3 mt-4 max-w-lg text-lg text-slate-700 ">
              A formatted, styled page, loaded in under 0.3 seconds.
            </p>
            <div className="col-start-1 row-start-4 mt-10 max-w-[36rem] flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
              <Link
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-blue-500 text-white hover:bg-slate-500"
                to="/sandbox/forms"
              >
                <span>
                  Form samples
                </span>
              </Link>
              <Link
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-white/0 text-blue-500 ring-1 ring-blue-500/10 hover:bg-white/25 hover:ring-blue-500/15 "
                to="/sandbox/login"
              >
                <span>
                  Sample Login
                </span>
              </Link>
            </div>
          </div>
        </div>
        </>
  );
}

export default IndexPage;
