/* eslint-disable-next-line */
import React from 'react';
import { w } from 'windstitch';


export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  return (
    <header className="relative z-50 w-full dark:bg-slate-900 flex-none text-sm font-semibold leading-6">
      <nav aria-label="Global" className="mx-auto max-w-container px-4 sm:px-6 lg:px-8">
          <div className="relative flex items-center py-[2.125rem]">
            <a className="mr-auto flex-none text-slate-900" href="/">
                <span className="sr-only">Tailwind UI</span>
                
            </a>
            <div className="hidden lg:flex lg:items-center">
                <a href="/allforms">Forms</a><a className="ml-8" href="/login">Login</a>
                <div className="ml-2 hidden rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white sm:block">New</div>
                <a className="ml-8" href="/register">Register</a>
            </div>
            
            <div className="hidden lg:ml-8 lg:flex lg:items-center lg:border-l lg:border-slate-900/15 lg:pl-8">
                <div className="relative">
                  <button className="flex items-center font-semibold hover:text-gray-900" id="headlessui-menu-button-1" type="button" aria-haspopup="true" aria-expanded="false">
                      <span className="hidden items-center sm:flex">
                        Form 2
                        
                      </span>
                      <span className="-my-1 flex h-8 w-8 items-center justify-center rounded-lg sm:hidden">
                        
                      </span>
                  </button>
                </div>
            </div>
          </div>
      </nav>
    </header>
  )
}

export default Header
